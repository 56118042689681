import React, { useEffect, useState } from 'react';
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  FloatingLabel,
  Image,
} from 'react-bootstrap';
import { Formik } from 'formik';
import { navigate } from 'gatsby';
import {
  errorMessage,
  descriptionText,
  checkBoxRow,
  checkBox,
  checkBoxNo,
  checkBoxText,
  smallPad,
  pad,
  chooseTerminalContainer,
  terminalText,
  terminalTextValidated,
  addButton,
  addButtonValidated,
  subtractButton,
  subtractButtonValidated,
  nextButton,
  questionInputField,
  validatedField,
  validatedFieldDistribution,
  questionNumber,
  questionBox,
  questionHeader,
  questionSubHeader,
  questionCol,
  numberCol,
  terminalImage,
  bigPad,
  centerText,
  linkColor,
} from './FlowManual.module.scss';
import useLanguageStore from '../LanguageStore/LanguageStore';
import useFlowCompanyDataStore from '../FlowStore/FlowCompanyDataStore';
import useFlowUserStore from '../FlowStore/FlowUserStore';
import ProgressBar from '../ProgressBar/ProgressBar';
import useFlowTerminalStore from '../FlowStore/FlowTerminalStore';
import StaticTerminal from '../../../static/images/terminal-static.jpg';
import InalambricoTerminal from '../../../static/images/terminal-wifi.jpg';
import MobileTerminal from '../../../static/images/terminal-mobile.jpg';
import useFlowProgressStore from '../FlowStore/FlowProgressStore';
import prefencesSchema from '../Validations/preferencesValidation';
import FlowTopText from './FlowTopText';
import FlowBottom from './FlowBottom';
import registerUserAPI from './apiFunctions/registerUserAPI';
import getProviderDataAPI from './apiFunctions/getProviderDataAPI';

type LanguageContent = {
  topText1: string;
  topText2: string;
  topText3: string;

  companyRFCName1: string;
  companyRFCName2: string;
  currentAcceptCardsText1: string;
  currentAcceptCardsText2: string;
  sector1: string;
  sector2: string;
  monthlyCardTurnoverText1: string;
  monthlyCardTurnoverText2: string;
  averageTransactionText1: string;
  averageTransactionText2: string;
  cardDistributionText1: string;
  cardDistributionText2: string;
  debitCardText: string;
  creditCardText: string;

  terminalsNeed: string;
  terminalsStaticHead: string;
  terminalsStaticBody: string;
  terminalsMobileHead: string;
  terminalsMobileBody: string;
  terminalsWirelessHead: string;
  terminalsWirelessBody: string;

  terminalStatic: string;
  terminalMobile: string;
  staticTerminal1: string;
  staticTerminal2: string;
  mobileTerminal1: string;
  mobileTerminal2: string;
  inalambricoTerminal1: string;
  inalambricoTerminal2: string;

  continueButton: string;
  yes: string;
  no: string;

  Email: string;
  EmailText: string;
  Phone: string;
  PhoneText: string;

  staticTerminal: string;
  inalambricoTerminal: string;
  mobileTerminal: string;

  bottomHead1: string;
  bottomText1: string;
  bottomHead2: string;
  bottomText2: string;

  waitMessage: string;
  errorMessageText: string;
};

const content: { [name: string]: LanguageContent } = {};
content.English = {
  topText1: 'Compare prices and offers on terminals point of sale',
  topText2: 'from various providers.',
  topText3:
    'Fill out the questionnaire in less than 1 minute to start the process.',

  companyRFCName1: 'Business Trading Name or RFC?',
  companyRFCName2: 'Fill your RFC or business trading name.',
  currentAcceptCardsText1: 'Do you currently accept cards?',
  currentAcceptCardsText2: 'Do you have a payment solution today?',
  sector1: 'Which sector are you operating in?',
  sector2: 'Which sector are you operating in?',

  monthlyCardTurnoverText1: 'Monthly card turnover?',
  monthlyCardTurnoverText2: 'Monthly card turnover?',
  averageTransactionText1: 'Average Transaction Size?',
  averageTransactionText2: 'Average Transaction Size?',
  cardDistributionText1:
    'Estimate the % of sales that take place across the following card types:',
  cardDistributionText2:
    'Estimate the % of sales that take place across the following card types:',
  debitCardText: 'Debit Card %',
  creditCardText: 'Credit Card %',

  terminalsNeed: 'Which type of terminal do you need?',
  terminalsStaticHead: 'Static Terminal',
  terminalsStaticBody: '',
  terminalsMobileHead: 'Mobile Terminal',
  terminalsMobileBody: '',
  terminalsWirelessHead: 'Wireless Terminal',
  terminalsWirelessBody: '',

  terminalStatic: 'Static',
  terminalMobile: 'Mobile',
  staticTerminal1: 'Static',
  staticTerminal2: 'Terminal',
  mobileTerminal1: 'Mobile',
  mobileTerminal2: 'Terminal',
  inalambricoTerminal1: 'Wireless',
  inalambricoTerminal2: 'Terminal',

  continueButton: 'Continue',
  yes: 'Yes',
  no: 'No',

  Email: 'Email',
  EmailText:
    'Put your email address so we can send notices on your selected offer.',
  Phone: 'Phone',
  PhoneText:
    'Put your phone number to receive updates and guidance about our quotes on payment solutions.',
  staticTerminal: 'Static terminal',
  inalambricoTerminal: 'Wireless terminal',
  mobileTerminal: 'Mobile terminal',

  bottomHead1: 'Immediate Offers',
  bottomText1:
    'Save time and money, compare and choose the solution that is best for you.',
  bottomHead2: '100% Free',
  bottomText2: 'Our service is 100% free and without any obligations.',

  waitMessage: 'Please wait, your results are loading',
  errorMessageText:
    'An error has occured, contact us and we will help you with your request: info@puntoterminal.mx, 56 4238 4307',
};
content.Español = {
  topText1: 'Compara precios y ofertas en terminales punto de venta',
  topText2: 'de varios proveedores.',
  topText3: 'Llena el formulario en menos de 1 minuto para empezar el proceso.',

  companyRFCName1: '¿RFC o nombre de la empresa?',
  companyRFCName2:
    'Pon el Nombre de la empresa o RFC (en caso de estar inscrito).',
  currentAcceptCardsText1: '¿Actualmente acepta tarjetas?',
  currentAcceptCardsText2: '¿Cuenta con un solución de pago hoy?',
  sector1: '¿En cuál sector está su negocio?',
  sector2: 'En cuál industria está operando su negocio.',

  monthlyCardTurnoverText1: '¿Cantidad de facturación por mes?',
  monthlyCardTurnoverText2:
    'Cuanto estiman que estan facturando por mes en transacciones de tarjetas.',
  averageTransactionText1: '¿Cantidad de la transacción en promedio?',
  averageTransactionText2: 'Estima una transacción en tipico en su negocio.',

  cardDistributionText1: 'Pon el % de ventas en tipos de tarjetas:',
  cardDistributionText2:
    'Estima el % de ventas que tiene en los siguentes tipos de tarjetas:',
  debitCardText: 'Tarjeta Debito %',
  creditCardText: 'Tarjeta Credito %',

  terminalsNeed: '¿Que tipo de terminal necesita?',
  terminalsStaticHead: 'Fijo (con conexión de internet vía cable):',
  terminalsStaticBody:
    'Con una terminal fijo cobras al lado de tu caja registradora. Gracias a la conexión a internet tienes un procesamiento veloz de tus transacciónes.',
  // Med en stationär kortterminal tar du betalt vid din kassaapparat. Tack vare anslutningen till internet får du en snabb hantering av dina transaktioner.
  terminalsMobileHead: 'Móvil (con conexión a un celular): ',
  terminalsMobileBody:
    'Una terminal móvil require conexión a un celular tipo smartphone para procesar pagos de tarjeta. ',
  // 'Med en trådlös kortterminal tar du betalt var du vill i din lokal. Terminalen skriver ut kvittot på plats. Du kan wälja att ansluta den med sim-kort som följer med eller wifi.',
  terminalsWirelessHead: 'Inalámbrica (con tarjeta sim 4G y Wifi): ',
  terminalsWirelessBody:
    'Con una terminal Inalámbrica puedes cobrar donde quiera en su local. El terminal punto de venta imprime el recibo. Puedes elegir de conectar el terminal con una tarjeta sim o con wifi.',
  terminalStatic: 'Fijo',
  terminalMobile: 'Móvil',
  staticTerminal1: 'Terminal',
  staticTerminal2: 'Fijo',
  mobileTerminal1: 'Terminal',
  mobileTerminal2: 'Móvil',
  inalambricoTerminal1: 'Terminal',
  inalambricoTerminal2: 'Inalámbrica',

  continueButton: 'Continuar',
  yes: 'Si',
  no: 'No',

  Email: 'Correo electrónico para persona de contacto',
  EmailText:
    'Pon tu correo para que te mandamos la oferta y actualicaciones de tu selección.',
  Phone: 'Teléfono para persona de contacto',
  PhoneText:
    'Pon tu numero para seguimento y consultaciónes sobre nuestros ofertas de pago.',

  staticTerminal: 'Terminal fijo',
  inalambricoTerminal: 'Terminal Inalámbrica',
  mobileTerminal: 'Terminal móvil',

  bottomHead1: 'Ofertas Inmediatas',
  bottomText1:
    'Ahorra dinero y tiempo, Compara y elige el solucion que es mejor para ustedes.',
  bottomHead2: 'Totalmente gratis',
  bottomText2: 'Nuestro servico es totalmente gratis y sin obligaciones.',

  waitMessage: 'Por favor espera, tus resultados estan cargando',
  errorMessageText:
    'Un error ha ocurido, contactanos y te ayudamos: info@puntoterminal.mx, 56 4238 4307',
};

const FlowManual = () => {
  // Placeholder:
  const companyRFCNamePlaceHolder = 'BNM840515VB1';
  const monthlyCardTurnoverPlaceHolder = 150000;
  const averageTransactionPlaceHolder = 1500;
  const emailPlaceHolder = 'nombre.apellido@empresa.mx';
  const phonePlaceHolder = '5512345678';

  // Loading Calculo Data
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  // Import states
  const currentAcceptingCards: boolean = useFlowCompanyDataStore(
    (state) => state.currentAcceptingCards
  );
  const companyRFCName: string = useFlowCompanyDataStore(
    (state) => state.companyRFCName
  );
  const sector: number = useFlowCompanyDataStore((state) => state.sector);
  const monthlyCardTurnover: number = useFlowCompanyDataStore(
    (state) => state.monthlyCardTurnover
  );
  const averageTransaction: number = useFlowCompanyDataStore(
    (state) => state.averageTransaction
  );
  const debitDistribution: number = useFlowCompanyDataStore(
    (state) => state.debitDistribution
  );
  const creditDistribution: number = useFlowCompanyDataStore(
    (state) => state.creditDistribution
  );

  const mobileTerminals: number = useFlowTerminalStore(
    (state) => state.mobileTerminals
  );
  const staticTerminals: number = useFlowTerminalStore(
    (state) => state.staticTerminals
  );
  const inalambricoTerminals: number = useFlowTerminalStore(
    (state) => state.inalambricoTerminals
  );

  const email: string = useFlowUserStore((state) => state.email);
  const phone: string = useFlowUserStore((state) => state.phone);

  // import update state functions
  const setCompanyRFCName = useFlowCompanyDataStore(
    (state) => state.setCompanyRFCName
  );

  const setCurrentAcceptingCards = useFlowCompanyDataStore(
    (state) => state.setCurrentAcceptingCards
  );

  const setSector = useFlowCompanyDataStore((state) => state.setSector);
  const setMonthlyCardTurnover = useFlowCompanyDataStore(
    (state) => state.setMonthlyCardTurnover
  );
  const setAverageTransaction = useFlowCompanyDataStore(
    (state) => state.setAverageTransaction
  );
  const setLastBill = useFlowCompanyDataStore((state) => state.setLastBill);
  const setDebitDistribution = useFlowCompanyDataStore(
    (state) => state.setDebitDistribution
  );
  const setCreditDistribution = useFlowCompanyDataStore(
    (state) => state.setCreditDistribution
  );

  const setMobileTerminals = useFlowTerminalStore(
    (state) => state.setMobileTerminals
  );

  const setStaticTerminals = useFlowTerminalStore(
    (state) => state.setStaticTerminals
  );

  const setInalambricoTerminals = useFlowTerminalStore(
    (state) => state.setInalambricoTerminals
  );

  const setEmail = useFlowUserStore((state) => state.setEmail);
  const setPhone = useFlowUserStore((state) => state.setPhone);

  const nextFlowPage = useFlowProgressStore((state) => state.nextFlowPage);
  const startFlowProgress = useFlowProgressStore(
    (state) => state.startFlowProgress
  );

  useEffect(() => {
    startFlowProgress();
  }, []);

  async function navigateChooseProvider(
    currentAcceptingCards: boolean,
    companyRFCName: string,
    sector: number,
    monthlyCardTurnover: number,
    averageTransaction: number,
    debitDistribution: number,
    creditDistribution: number,
    mobileTerminals: number,
    staticTerminals: number,
    inalambricoTerminals: number,
    email: string
  ) {
    const res = await getProviderDataAPI(
      currentAcceptingCards,
      sector,
      monthlyCardTurnover,
      averageTransaction,
      debitDistribution,
      creditDistribution,
      mobileTerminals,
      staticTerminals,
      inalambricoTerminals,
      email
    );

    if (res) {
      navigate('/elige-proveedor');
      // Do not set loading to false here, it will make the formulario flash by before sending to FlowChooseProvider.
    } else {
      setLoading(false);
      setError(true);
    }
  }

  const language = useLanguageStore((state) => state.language);
  let choosenLanguage: LanguageContent;
  language === 'Español'
    ? (choosenLanguage = content.Español)
    : (choosenLanguage = content.English);

  if (loading) {
    return (
      <Container>
        <div className={bigPad} />
        <h2 className={centerText} data-testid="loadingMsg">
          {choosenLanguage.waitMessage}
        </h2>
        <div className={bigPad} />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <div className={bigPad} />
        <h2 className={centerText}>
          <a className={linkColor} href="mailto:info@puntoterminal.mx">
            {' '}
            {choosenLanguage.errorMessageText}{' '}
          </a>
        </h2>
        <div className={bigPad} />
      </Container>
    );
  }

  return (
    <>
      <FlowTopText
        text1={choosenLanguage.topText1}
        text2={choosenLanguage.topText2}
        text3={choosenLanguage.topText3}
      />
      <ProgressBar />
      <Container id="FormContainer">
        <div className={pad} />

        <Formik
          initialValues={{
            currentAcceptingCards,
            companyRFCName,
            sector,
            monthlyCardTurnover,
            averageTransaction,
            debitDistribution,
            creditDistribution,
            staticTerminals,
            inalambricoTerminals,
            mobileTerminals,
            email,
            phone,
          }}
          validationSchema={prefencesSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            setLoading(true);

            // Set values to zustand state
            setCompanyRFCName(values.companyRFCName);
            setCurrentAcceptingCards(values.currentAcceptingCards);
            setSector(values.sector);
            setMonthlyCardTurnover(values.monthlyCardTurnover);
            setAverageTransaction(values.averageTransaction);
            setDebitDistribution(values.debitDistribution);
            setCreditDistribution(values.creditDistribution);
            setMobileTerminals(values.mobileTerminals);
            setStaticTerminals(values.staticTerminals);
            setInalambricoTerminals(values.inalambricoTerminals);
            setEmail(values.email);
            setPhone(values.phone);
            // Go to next page
            nextFlowPage();

            const success = await registerUserAPI(
              values.companyRFCName,
              values.phone,
              values.email
            );
            if (success === true) {
              await navigateChooseProvider(
                values.currentAcceptingCards,
                values.companyRFCName,
                values.sector,
                values.monthlyCardTurnover,
                values.averageTransaction,
                values.debitDistribution,
                values.creditDistribution,
                values.mobileTerminals,
                values.staticTerminals,
                values.inalambricoTerminals,
                values.email
              );
            } else if (success === 'USER EXISTS') {
              // FUTURE FIX: Send user to LOGIN and LET USER CHOOSE the option THEMSELVES!
              // For now just show error message with contact details.
              alert(
                'Un Usario con estos detalles de contacto ya existe! Contactanos y te ayudamos a cambiar oferta: info@puntoterminal.mx, 56 4238 4307'
              );
            } else {
              alert(
                'Un error ha ocurido, contactanos y te ayudamos: info@puntoterminal.mx, 56 4238 4307'
              );
            }

            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validateField,
            setFieldTouched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Row className={questionBox} xs={1} sm={1} md={2} lg={2}>
                <Col>
                  <Row xs={1} sm={1} md={2} lg={2}>
                    <Col className={numberCol}>
                      <span className={questionNumber}>1</span>
                    </Col>
                    <Col className={questionCol}>
                      <h4 className={questionHeader}>
                        {choosenLanguage.currentAcceptCardsText1}
                      </h4>

                      <p className={questionSubHeader}>
                        {choosenLanguage.currentAcceptCardsText2}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="acceptingCards">
                    <Row>
                      <Col />
                      <Col className="justify-content-left">
                        <Form.Check
                          type="checkbox"
                          id="custom-switch"
                          name="currentAcceptingCards"
                          className={questionInputField}
                        >
                          <Row className={checkBoxRow}>
                            <Form.Check.Input
                              className={
                                values.currentAcceptingCards === false
                                  ? checkBoxNo
                                  : checkBox
                              }
                              checked={values.currentAcceptingCards}
                              onChange={(e) =>
                                setFieldValue(
                                  'currentAcceptingCards',
                                  e.target.checked
                                )
                              }
                            />
                            <Form.Check.Label className={checkBoxText}>
                              {values.currentAcceptingCards === false
                                ? `${choosenLanguage.no}`
                                : `${choosenLanguage.yes}`}
                            </Form.Check.Label>
                          </Row>
                        </Form.Check>
                      </Col>
                    </Row>
                    {touched.currentAcceptingCards &&
                    errors.currentAcceptingCards ? (
                      <div className={errorMessage}>
                        {errors.currentAcceptingCards}
                      </div>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>

              <Row className={questionBox} xs={1} sm={1} md={2} lg={2}>
                <Col>
                  <Row xs={1} sm={1} md={2} lg={2}>
                    <Col className={numberCol}>
                      <span className={questionNumber}>2</span>
                    </Col>
                    <Col className={questionCol}>
                      <h4 className={questionHeader}>
                        {choosenLanguage.companyRFCName1}
                      </h4>

                      <p className={questionSubHeader}>
                        {choosenLanguage.companyRFCName2}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="companyRFCName">
                    <Form.Control
                      type="text"
                      placeholder={companyRFCNamePlaceHolder}
                      name="companyRFCName"
                      data-testid="companyRFCName"
                      value={values.companyRFCName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        touched.companyRFCName && !errors.companyRFCName
                          ? validatedField
                          : questionInputField
                      }
                    />

                    {touched.companyRFCName && errors.companyRFCName ? (
                      <div className={errorMessage}>
                        {errors.companyRFCName}
                      </div>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>

              <Row className={questionBox} xs={1} sm={1} md={2} lg={2}>
                <Col>
                  <Row xs={1} sm={1} md={2} lg={2}>
                    <Col className={numberCol}>
                      <span className={questionNumber}>3</span>
                    </Col>
                    <Col className={questionCol}>
                      <h4 className={questionHeader}>
                        {choosenLanguage.sector1}
                      </h4>

                      <p className={questionSubHeader}>
                        {choosenLanguage.sector2}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="sector">
                    <Form.Select
                      name="sector"
                      data-testid="sector"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.sector}
                      className={
                        touched.sector && !errors.sector
                          ? validatedField
                          : questionInputField
                      }
                    >
                      <option value={0}>No seleccionado</option>
                      <option value={1}>Agencias de viajes</option>
                      <option value={2}>Aseguradoras</option>
                      <option value={3}>Colegios y universidades</option>
                      <option value={4}>Comida rápida</option>
                      <option value={5}>Educación básica</option>
                      <option value={6}>Entretenimiento</option>
                      <option value={7}>Estacionamientos</option>
                      <option value={8}>Farmacias</option>
                      <option value={9}>Gasolineras</option>
                      <option value={10}>Guarderías</option>
                      <option value={11}>Hospitales</option>
                      <option value={12}>Hoteles</option>
                      <option value={13}>Médicos y dentistas</option>
                      <option value={14}>Misceláneas</option>
                      <option value={15}>Otros</option>
                      <option value={16}>Peaje</option>
                      <option value={17}>Refacciones y ferreterías</option>
                      <option value={18}>Renta de autos</option>
                      <option value={19}>Restaurantes</option>
                      <option value={20}>Salones de belleza</option>
                      <option value={21}>Supermercados</option>
                      <option value={22}>Telecomunicaciones</option>
                      <option value={23}>Transporte aéreo</option>
                      <option value={24}>
                        Transporte terrestre de pasajeros
                      </option>
                      <option value={25}>Ventas al menudeo</option>
                    </Form.Select>

                    {touched.sector && errors.sector ? (
                      <div className={errorMessage}>{errors.sector}</div>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>

              <Row className={questionBox} xs={1} sm={1} md={2} lg={2}>
                <Col>
                  <Row xs={1} sm={1} md={2} lg={2}>
                    <Col className={numberCol}>
                      <span className={questionNumber}>4</span>
                    </Col>
                    <Col className={questionCol}>
                      <h4 className={questionHeader}>
                        {choosenLanguage.monthlyCardTurnoverText1}
                      </h4>

                      <p className={questionSubHeader}>
                        {choosenLanguage.monthlyCardTurnoverText2}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="monthlyCardTurnover">
                    <Form.Control
                      type="number"
                      placeholder={monthlyCardTurnoverPlaceHolder}
                      name="monthlyCardTurnover"
                      data-testid="monthlyCardTurnover"
                      value={values.monthlyCardTurnover}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        touched.monthlyCardTurnover &&
                        !errors.monthlyCardTurnover
                          ? validatedField
                          : questionInputField
                      }
                    />

                    {touched.monthlyCardTurnover &&
                    errors.monthlyCardTurnover ? (
                      <div className={errorMessage}>
                        {errors.monthlyCardTurnover}
                      </div>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>

              <Row className={questionBox} xs={1} sm={1} md={2} lg={2}>
                <Col>
                  <Row xs={1} sm={1} md={2} lg={2}>
                    <Col className={numberCol}>
                      <span className={questionNumber}>5</span>
                    </Col>
                    <Col className={questionCol}>
                      <h4 className={questionHeader}>
                        {choosenLanguage.averageTransactionText1}
                      </h4>

                      <p className={questionSubHeader}>
                        {choosenLanguage.averageTransactionText2}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="averageTransaction">
                    <Form.Control
                      type="number"
                      placeholder={averageTransactionPlaceHolder}
                      name="averageTransaction"
                      data-testid="averageTransaction"
                      value={values.averageTransaction}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        touched.averageTransaction && !errors.averageTransaction
                          ? validatedField
                          : questionInputField
                      }
                    />

                    {touched.averageTransaction && errors.averageTransaction ? (
                      <div className={errorMessage}>
                        {errors.averageTransaction}
                      </div>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>

              {values.currentAcceptingCards === true ? (
                <div>
                  <Row className={questionBox} xs={1} sm={1} md={2} lg={2}>
                    <Col>
                      <Row xs={1} sm={1} md={2} lg={2}>
                        <Col className={numberCol}>
                          <span className={questionNumber}>6</span>
                        </Col>
                        <Col className={questionCol}>
                          <h4 className={questionHeader}>
                            {choosenLanguage.cardDistributionText1}
                          </h4>

                          <p className={questionSubHeader}>
                            {choosenLanguage.cardDistributionText2}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row className="g-2">
                        <Col md>
                          <Form.Group
                            className="mb-3"
                            controlId="debitDistribution"
                          >
                            <FloatingLabel
                              controlId="floatingInputGrid"
                              label={choosenLanguage.debitCardText}
                              className={
                                touched.debitDistribution &&
                                !errors.debitDistribution
                                  ? validatedFieldDistribution
                                  : questionInputField
                              }
                            >
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="debitDistribution"
                                data-testid="debitDistribution"
                                value={values.debitDistribution}
                                className={
                                  touched.debitDistribution &&
                                  !errors.debitDistribution
                                    ? validatedField
                                    : questionInputField
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onInput={(e) => {
                                  setFieldValue(
                                    'debitDistribution',
                                    e.target.value
                                  );
                                  setFieldValue(
                                    'creditDistribution',
                                    100 - e.target.value
                                  );
                                  validateField('debitDistribution');
                                  validateField('creditDistribution');
                                }}
                              />

                              {touched.debitDistribution &&
                              errors.debitDistribution ? (
                                <div className={errorMessage}>
                                  {errors.debitDistribution}
                                </div>
                              ) : null}
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col md>
                          <Form.Group
                            className="mb-3"
                            controlId="creditDistribution"
                          >
                            <FloatingLabel
                              controlId="floatingSelectGrid"
                              label={choosenLanguage.creditCardText}
                              className={
                                touched.creditDistribution &&
                                !errors.creditDistribution
                                  ? validatedFieldDistribution
                                  : questionInputField
                              }
                            >
                              <Form.Control
                                type="number"
                                placeholder=""
                                name="creditDistribution"
                                data-testid="creditDistribution"
                                value={values.creditDistribution}
                                className={
                                  touched.creditDistribution &&
                                  !errors.creditDistribution
                                    ? validatedField
                                    : questionInputField
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onInput={(e) => {
                                  setFieldValue(
                                    'creditDistribution',
                                    e.target.value
                                  );
                                  setFieldValue(
                                    'debitDistribution',
                                    100 - e.target.value
                                  );
                                  validateField('debitDistribution');
                                  validateField('creditDistribution');
                                }}
                              />

                              {touched.creditDistribution &&
                              errors.creditDistribution ? (
                                <div className={errorMessage}>
                                  {errors.creditDistribution}
                                </div>
                              ) : null}
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              ) : null}

              <Row className={questionBox} xs={1} sm={1} md={1} lg={2}>
                <Col>
                  <Row xs={1} sm={1} md={1} lg={2}>
                    <Col className={numberCol}>
                      <span className={questionNumber}>7</span>
                    </Col>
                    <Col className={questionCol}>
                      <h4 className={questionHeader}>
                        {choosenLanguage.terminalsNeed}
                      </h4>
                      <p className={questionSubHeader}>
                        <strong>{choosenLanguage.terminalsStaticHead} </strong>
                      </p>
                      <p className={questionSubHeader}>
                        {choosenLanguage.terminalsStaticBody}
                      </p>
                      <p className={questionSubHeader}>
                        <strong>{choosenLanguage.terminalsWirelessHead}</strong>
                      </p>
                      <p className={questionSubHeader}>
                        {choosenLanguage.terminalsWirelessBody}
                      </p>
                      <p className={questionSubHeader}>
                        <strong>{choosenLanguage.terminalsMobileHead}</strong>
                      </p>
                      <p className={questionSubHeader}>
                        {choosenLanguage.terminalsMobileBody}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <div>
                    <Container className={chooseTerminalContainer}>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                          <Image
                            className={terminalImage}
                            src={StaticTerminal}
                            alt="Terminal fijo haciendo compra con tarjeta credito"
                            title="Terminal fijo haciendo compra con tarjeta credito"
                            fluid
                          />

                          <div className="d-grid gap-2">
                            <Row>
                              <div
                                className={`${
                                  touched.staticTerminals &&
                                  !errors.staticTerminals &&
                                  values.staticTerminals > 0
                                    ? terminalTextValidated
                                    : terminalText
                                }`}
                                name="staticTerminals"
                                onClick={() => {
                                  let value = values.staticTerminals;
                                  if (value === 0) {
                                    value += 1;
                                    setFieldValue('staticTerminals', value);
                                    setFieldValue('inalambricoTerminals', 0);
                                    setFieldValue('mobileTerminals', 0);
                                    setFieldTouched('staticTerminals');
                                  }
                                }}
                                onBlur={handleBlur}
                              >
                                <strong>
                                  {choosenLanguage.staticTerminal1}
                                  <br />
                                  {choosenLanguage.staticTerminal2}
                                </strong>
                              </div>
                            </Row>
                            <Row>
                              <Col>
                                <div className={smallPad} />
                                <Button
                                  variant="primary"
                                  name="staticTerminalsSubtract"
                                  className={`${
                                    touched.staticTerminals &&
                                    !errors.staticTerminals &&
                                    values.staticTerminals > 0
                                      ? subtractButtonValidated
                                      : subtractButton
                                  }`}
                                  onClick={() => {
                                    let value = values.staticTerminals;
                                    if (value >= 1) {
                                      value -= 1;
                                      setFieldValue('staticTerminals', value);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                >
                                  -
                                </Button>
                              </Col>

                              <Col>
                                <div className={smallPad} />
                                <p>{values.staticTerminals}</p>
                              </Col>

                              <Col>
                                <div className={smallPad} />
                                <Button
                                  variant="primary"
                                  name="staticTerminalsAdd"
                                  data-testid="staticTerminalsAdd"
                                  className={`${
                                    touched.staticTerminals &&
                                    !errors.staticTerminals &&
                                    values.staticTerminals > 0
                                      ? addButtonValidated
                                      : addButton
                                  }`}
                                  onClick={() => {
                                    let value = values.staticTerminals;
                                    value += 1;
                                    setFieldValue('staticTerminals', value);
                                    setFieldValue('inalambricoTerminals', 0);
                                    setFieldValue('mobileTerminals', 0);
                                  }}
                                  onBlur={handleBlur}
                                >
                                  +
                                </Button>
                              </Col>
                              {touched.staticTerminals &&
                              errors.staticTerminals ? (
                                <div className={errorMessage}>
                                  {errors.staticTerminals}
                                </div>
                              ) : null}
                            </Row>

                            <div className={smallPad} />
                          </div>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                          <Image
                            className={terminalImage}
                            src={InalambricoTerminal}
                            alt="Terminal inalambrico haciendo compra con tarjeta credito"
                            title="Terminal inalambrico haciendo compra con tarjeta credito"
                            fluid
                          />
                          <div className="d-grid gap-2">
                            <Row>
                              <div
                                className={`${
                                  touched.inalambricoTerminals &&
                                  !errors.inalambricoTerminals &&
                                  values.inalambricoTerminals > 0
                                    ? terminalTextValidated
                                    : terminalText
                                }`}
                                name="inalambricoTerminals"
                                onClick={() => {
                                  let value = values.inalambricoTerminals;
                                  if (value === 0) {
                                    value += 1;
                                    setFieldValue('staticTerminals', 0);
                                    setFieldValue(
                                      'inalambricoTerminals',
                                      value
                                    );
                                    setFieldValue('mobileTerminals', 0);
                                    setFieldTouched('inalambricoTerminals');
                                  }
                                }}
                                onBlur={handleBlur}
                              >
                                <strong>
                                  {choosenLanguage.inalambricoTerminal1}
                                  <br />
                                  {choosenLanguage.inalambricoTerminal2}
                                </strong>
                              </div>
                            </Row>
                            <Row>
                              <Col>
                                <div className={smallPad} />
                                <Button
                                  variant="primary"
                                  name="inalambricoTerminalsSubtract"
                                  className={`${
                                    touched.inalambricoTerminals &&
                                    !errors.inalambricoTerminals &&
                                    values.inalambricoTerminals > 0
                                      ? subtractButtonValidated
                                      : subtractButton
                                  }`}
                                  onClick={() => {
                                    let value = values.inalambricoTerminals;
                                    if (value >= 1) {
                                      value -= 1;
                                      setFieldValue(
                                        'inalambricoTerminals',
                                        value
                                      );
                                    }
                                  }}
                                  onBlur={handleBlur}
                                >
                                  -
                                </Button>
                              </Col>

                              <Col>
                                <div className={smallPad} />
                                <p>{values.inalambricoTerminals}</p>
                              </Col>

                              <Col>
                                <div className={smallPad} />
                                <Button
                                  variant="primary"
                                  name="inalambricoTerminalsAdd"
                                  data-testid="inalambricoTerminalsAdd"
                                  className={`${
                                    touched.inalambricoTerminals &&
                                    !errors.inalambricoTerminals &&
                                    values.inalambricoTerminals > 0
                                      ? addButtonValidated
                                      : addButton
                                  }`}
                                  onClick={() => {
                                    let value = values.inalambricoTerminals;
                                    value += 1;
                                    setFieldValue('staticTerminals', 0);
                                    setFieldValue(
                                      'inalambricoTerminals',
                                      value
                                    );
                                    setFieldValue('mobileTerminals', 0);
                                  }}
                                  onBlur={handleBlur}
                                >
                                  +
                                </Button>
                              </Col>
                              {touched.inalambricoTerminals &&
                              errors.inalambricoTerminals ? (
                                <div className={errorMessage}>
                                  {errors.inalambricoTerminals}
                                </div>
                              ) : null}
                            </Row>

                            <div className={smallPad} />
                          </div>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                          <Image
                            className={terminalImage}
                            src={MobileTerminal}
                            alt="Terminal movil haciendo compra con tarjeta credito"
                            title="Terminal movil haciendo compra con tarjeta credito"
                            fluid
                          />
                          <div className="d-grid gap-2">
                            <Row>
                              <div
                                className={`${
                                  touched.mobileTerminals &&
                                  !errors.mobileTerminals &&
                                  values.mobileTerminals > 0
                                    ? terminalTextValidated
                                    : terminalText
                                }`}
                                name="mobileTerminals"
                                onClick={() => {
                                  let value = values.mobileTerminals;
                                  if (value === 0) {
                                    value += 1;
                                    setFieldValue('staticTerminals', 0);
                                    setFieldValue('inalambricoTerminals', 0);
                                    setFieldValue('mobileTerminals', value);
                                    setFieldTouched('mobileTerminals');
                                  }
                                }}
                                onBlur={handleBlur}
                              >
                                <strong>
                                  {choosenLanguage.mobileTerminal1}
                                  <br />
                                  {choosenLanguage.mobileTerminal2}
                                </strong>
                              </div>
                            </Row>
                            <Row>
                              <Col>
                                <div className={smallPad} />
                                <Button
                                  variant="primary"
                                  name="mobileTerminalsSubtract"
                                  className={`${
                                    touched.mobileTerminals &&
                                    !errors.mobileTerminals &&
                                    values.mobileTerminals > 0
                                      ? subtractButtonValidated
                                      : subtractButton
                                  }`}
                                  onClick={() => {
                                    let value = values.mobileTerminals;
                                    if (value >= 1) {
                                      value -= 1;
                                      setFieldValue('mobileTerminals', value);
                                    }
                                  }}
                                  onBlur={handleBlur}
                                >
                                  -
                                </Button>
                              </Col>

                              <Col>
                                <div className={smallPad} />
                                <p>{values.mobileTerminals}</p>
                              </Col>

                              <Col>
                                <div className={smallPad} />
                                <Button
                                  variant="primary"
                                  name="mobileTerminalsAdd"
                                  data-testid="mobileTerminalsAdd"
                                  className={`${
                                    touched.mobileTerminals &&
                                    !errors.mobileTerminals &&
                                    values.mobileTerminals > 0
                                      ? addButtonValidated
                                      : addButton
                                  }`}
                                  onClick={() => {
                                    let value = values.mobileTerminals;
                                    value += 1;
                                    setFieldValue('staticTerminals', 0);
                                    setFieldValue('inalambricoTerminals', 0);
                                    setFieldValue('mobileTerminals', value);
                                  }}
                                  onBlur={handleBlur}
                                >
                                  +
                                </Button>
                              </Col>

                              {touched.mobileTerminals &&
                              errors.mobileTerminals ? (
                                <div className={errorMessage}>
                                  {errors.mobileTerminals}
                                </div>
                              ) : null}
                            </Row>

                            <div className={smallPad} />
                          </div>
                        </Col>
                      </Row>
                      <div className={pad} />
                    </Container>
                  </div>
                </Col>
              </Row>

              <Row className={questionBox} xs={1} sm={1} md={2} lg={2}>
                <Col>
                  <Row xs={1} sm={1} md={2} lg={2}>
                    <Col className={numberCol}>
                      <span className={questionNumber}>8</span>
                    </Col>
                    <Col className={questionCol}>
                      <h4 className={questionHeader}>
                        {choosenLanguage.Email}
                      </h4>

                      <p className={questionSubHeader}>
                        {choosenLanguage.EmailText}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Control
                      type="text"
                      placeholder={emailPlaceHolder}
                      name="email"
                      data-testid="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        touched.email && !errors.email
                          ? validatedField
                          : questionInputField
                      }
                    />
                    {touched.email && errors.email ? (
                      <div className={errorMessage}>{errors.email}</div>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>

              <Row className={questionBox} xs={1} sm={1} md={2} lg={2}>
                <Col>
                  <Row xs={1} sm={1} md={2} lg={2}>
                    <Col className={numberCol}>
                      <span className={questionNumber}>9</span>
                    </Col>
                    <Col className={questionCol}>
                      <h4 className={questionHeader}>
                        {choosenLanguage.Phone}
                      </h4>

                      <p className={questionSubHeader}>
                        {choosenLanguage.PhoneText}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="phone">
                    <Form.Control
                      type="text"
                      placeholder={phonePlaceHolder}
                      name="phone"
                      data-testid="phone"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        touched.phone && !errors.phone
                          ? validatedField
                          : questionInputField
                      }
                    />
                    {touched.phone && errors.phone ? (
                      <div className={errorMessage}>{errors.phone}</div>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>

              <div className={pad} />

              <Button
                type="submit"
                data-testid="submitBtn"
                disabled={isSubmitting}
                className={nextButton}
                variant="success"
                size="lg"
              >
                Next
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
      <div className={pad} />
      <FlowBottom />
    </>
  );
};

export default FlowManual;
