import * as nprogress from 'nprogress';
import axios from 'axios';

import 'nprogress/nprogress.css';

const apiURL = process.env.GATSBY_API_URL;

async function registerUserAPI(companyRFCName, phone, email) {
  nprogress.start();

  // In the first stage of the questionnaire the names are not set.
  // Name and Password are set in the third stage as for 2022/10/20.
  const firstName = 'First name, not set, Company Data stage';
  const lastName = 'Last name, not set, Company Data stage';
  const passw = 'Password not set yet';
  const addr = 'Address not set yet';
  const postal = 'Postal code not set yet';
  const city_var = 'City not set yet';

  const contact_data = {
    // Step 3
    // Contact Form
    name: firstName,
    last_name: lastName,
    email,
    password: passw,
    phone,
    company_name: companyRFCName,
    address: addr,
    postal_code: postal,
    city: city_var,
  };

  const urlRegisterUser = `${apiURL}/register`;
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  let success;
  try {
    const res = await axios.post(urlRegisterUser, JSON.stringify(contact_data));
    // console.log(res.data);
    success = true;
  } catch (error) {
    switch (error.response.status) {
      case 400:
        // console.error(error.response.status, error.message);
        // console.log('A user with this email already exists!');
        success = 'USER EXISTS';
        break;

      case 422:
        // console.error(
        //   error.response.status,
        //   error.message,
        //   error.response.data.detail
        // );
        // console.log('Validation error');
        success = false;
        break;

      default:
        success = false;
      // console.error(error.response.status, error.message);
    }
    nprogress.done();
  }
  return success;
}

export default registerUserAPI;
