/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FunctionComponent } from 'react';
import Header from '../komponents/Header/Header';
import Footer from '../komponents/Footer/Footer';
import FlowManual from '../komponents/Flow/FlowManual';

const ComparaData: FunctionComponent = () => (
  <>
    <Header />

    <FlowManual />

    <Footer />
  </>
);

export default ComparaData;

export const Head = () => (
  <>
    <meta charSet="utf-8" />
    <meta name="description" content="Compara Terminal Punto de Venta" />
    <title>Preferencias - PuntoTerminal.mx</title>
  </>
);
