import * as yup from 'yup';

const prefencesSchema = yup.object().shape({
  currentAcceptingCards: yup.boolean().required(),
  companyRFCName: yup
    .string()
    .min(3, 'Por favor ponga el RFC o nombre de su empresa.')
    .required('Por favor ponga el RFC o nombre de su empresa.'),
  sector: yup
    .number()
    .min(1, 'Por favor elige un sector')
    .max(100, 'Must be less than 100')
    .positive('Por favor elige un sector')
    .required('Por favor elige un sector'),
  monthlyCardTurnover: yup
    .number()
    .typeError(
      'Por favor ponga un estimado de cantidad de facturación por mes.'
    )
    .min(1, 'Must have one integer')
    .max(999999999, 'Must be less than 999999999')
    .positive(
      'La cantidad de facturación por mes tiene que ser un número positivo.'
    )
    .required(
      'Por favor ponga un estimado de cantidad de facturación por mes.'
    ),
  averageTransaction: yup
    .number()
    .typeError('Por favor ponga un estimado de transacción promedio.')
    .min(1, 'Must have one integer')
    .max(999999999, 'Must be less than 999999999')
    .positive(
      'El estimado de transacción promedio por mes tiene que ser un número positivo.'
    )
    .required('Por favor ponga un estimado de transacción promedio.'),
  debitDistribution: yup
    .number()
    .typeError(
      'El porcentaje de la distribución de tarjetas débito tiene que ser un número.'
    )
    .min(0, 'Tiene que ser un número positivo o cero.')
    .max(100, 'No puede exceder 100 %.')
    .required('Por favor estima la distribución de tarjetas débito.'),
  creditDistribution: yup
    .number()
    .typeError(
      'El porcentaje de la distribución de tarjetas crédito tiene que ser un número.'
    )
    .min(0, 'Tiene que ser un número positivo o cero.')
    .max(100, 'No puede exceder 100 %.')
    .required('Por favor estima la distribución de tarjetas crédito.')
    .when('debitDistribution', (debitDistribution, schema) =>
      schema.test(
        'equals',
        'La suma de los campos de distribución de tarjetas tiene que ser 100 %.',
        function checkDistribution() {
          const { creditDistribution } = this.parent;
          return debitDistribution === 100 - creditDistribution;
        }
      )
    ),
  mobileTerminals: yup
    .number()
    .test(
      'oneOfRequired',
      'Tienes que eligir minimo una terminal.',
      function (item) {
        return (
          this.parent.mobileTerminals ||
          this.parent.staticTerminals ||
          this.parent.inalambricoTerminals
        );
      }
    ),
  staticTerminals: yup
    .number()
    .test(
      'oneOfRequired',
      'Tienes que eligir minimo una terminal.',
      function (item) {
        return (
          this.parent.mobileTerminals ||
          this.parent.staticTerminals ||
          this.parent.inalambricoTerminals
        );
      }
    ),
  inalambricoTerminals: yup
    .number()
    .test(
      'oneOfRequired',
      'Tienes que eligir minimo una terminal.',
      function (item) {
        return (
          this.parent.mobileTerminals ||
          this.parent.staticTerminals ||
          this.parent.inalambricoTerminals
        );
      }
    ),

  email: yup
    .string()
    .email('Este correo electrónico es invalido.')
    .required(
      'Por favor ponga su correo electrónico para que le mandamos la oferta y actualicaciones de su selección.'
    ),
  phone: yup
    .string()
    .required(
      'Por favor ponga el número telefónico para seguimento y consultas sobre nuestros ofertas de pago.'
    )
    .matches(/^[0-9]+$/, 'El número telefónico solo puede contener dígitos.')
    .min(10, 'El número debe ser de 10 dígitos.')
    .max(10, 'El número debe ser de 10 dígitos.'),

  // password: yup.string().required().min(5),
  //   passwordConfirm: yup
  //     .string()
  //     .required()
  //     .oneOf([yup.ref('Password')], 'Passwords do not match'),
});

export default prefencesSchema;
