// extracted by mini-css-extract-plugin
export var addButton = "FlowManual-module--addButton--b026b";
export var addButtonValidated = "FlowManual-module--addButtonValidated--b4aeb";
export var bigPad = "FlowManual-module--bigPad--958a0";
export var centerText = "FlowManual-module--centerText--bf39a";
export var checkBox = "FlowManual-module--checkBox--8d0de";
export var checkBoxNo = "FlowManual-module--checkBoxNo--60e3d";
export var checkBoxRow = "FlowManual-module--checkBoxRow--d4fa8";
export var checkBoxText = "FlowManual-module--checkBoxText--1f9e3";
export var chooseTerminalContainer = "FlowManual-module--chooseTerminalContainer--1851d";
export var descriptionText = "FlowManual-module--descriptionText--86e00";
export var errorMessage = "FlowManual-module--errorMessage--89eb4";
export var gprsTerminalText = "FlowManual-module--gprsTerminalText--8e9bc";
export var linkColor = "FlowManual-module--linkColor--25cd5";
export var nextButton = "FlowManual-module--nextButton--0cd2f";
export var numberCol = "FlowManual-module--numberCol--ce76f";
export var pad = "FlowManual-module--pad--f7611";
export var questionBox = "FlowManual-module--questionBox--b86dd";
export var questionCol = "FlowManual-module--questionCol--a8706";
export var questionHeader = "FlowManual-module--questionHeader--ad3b0";
export var questionInputField = "FlowManual-module--questionInputField--32b35";
export var questionNumber = "FlowManual-module--questionNumber--c4ab9";
export var questionSubHeader = "FlowManual-module--questionSubHeader--18996";
export var smallPad = "FlowManual-module--smallPad--55815";
export var subtractButton = "FlowManual-module--subtractButton--c62a5";
export var subtractButtonValidated = "FlowManual-module--subtractButtonValidated--d1868";
export var terminalImage = "FlowManual-module--terminalImage--60e15";
export var terminalText = "FlowManual-module--terminalText--ec9d5";
export var terminalTextValidated = "FlowManual-module--terminalTextValidated--886bf";
export var validatedField = "FlowManual-module--validatedField--d8b3f";
export var validatedFieldDistribution = "FlowManual-module--validatedFieldDistribution--505fe";
export var wifiTerminalText = "FlowManual-module--wifiTerminalText--78d48";