import * as nprogress from 'nprogress';
import axios from 'axios';
import useFlowProviderStore from '../../FlowStore/FlowProviderStore';

import 'nprogress/nprogress.css';

const apiURL = process.env.GATSBY_API_URL;

async function getProviderDataAPI(
  currentAcceptingCards: boolean,
  sector: number,
  monthlyCardTurnover: number,
  averageTransaction: number,
  debitDistribution: number,
  creditDistribution: number,
  mobileTerminals: number,
  staticTerminals: number,
  inalambricoTerminals: number,
  email: string
) {
  nprogress.start();

  // Import states
  const { setProviderData } = useFlowProviderStore.getState();

  const debitDist = debitDistribution / 100;
  const creditDist = creditDistribution / 100;
  const calculo_data = {
    // Step 1
    email, // To find the user.
    currentAcceptingCards,
    sector,
    monthlyCardTurnover,
    averageTransaction,
    debitDistribution: debitDist,
    creditDistribution: creditDist,

    // Choose Terminals
    mobileTerminals,
    staticTerminals,
    wifiTerminals: inalambricoTerminals, // REMOVE FROM BACKEND
    gprsTerminals: inalambricoTerminals, // REMOVE FROM BACKEND
  };

  const urlCalculo = `${apiURL}/calculo`;
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  let success = false;
  try {
    const res = await axios.post(urlCalculo, JSON.stringify(calculo_data));
    // console.log(res.data);
    setProviderData(res.data);
    success = true;
  } catch (error) {
    console.log(error);
  }

  nprogress.done();
  return success;
}

export default getProviderDataAPI;
