import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type ProviderStoreState = {
  chosenProvider: number;
  tasaDescuentoCredito: number;
  tasaDescuentoDebito: number;
  totalYearCost: number;
  providerData: any[]; // fetched from backend.

  setChosenProvider: (newChosenProvider: number) => void;
  setTasaDescuentoCredito: (newTasaDescuentoCredito: number) => void;
  setTasaDescuentoDebito: (newTasaDescuentoDebito: number) => void;
  setTotalYearCost: (newTotalYearCost: number) => void;
  setProviderData: (newProviderData: any[]) => void;
};

const useFlowProviderStore = create<ProviderStoreState>()(
  persist(
    (set) => ({
      chosenProvider: 0,
      tasaDescuentoCredito: 0,
      tasaDescuentoDebito: 0,
      totalYearCost: 0,
      providerData: [],

      // Data for chosen provider
      setChosenProvider: (newChosenProvider) =>
        set({ chosenProvider: newChosenProvider }),
      setTasaDescuentoCredito: (newTasaDescuentoCredito) =>
        set({ tasaDescuentoCredito: newTasaDescuentoCredito }),
      setTasaDescuentoDebito: (newTasaDescuentoDebito) =>
        set({ tasaDescuentoDebito: newTasaDescuentoDebito }),
      setTotalYearCost: (newTotalYearCost) =>
        set({ totalYearCost: newTotalYearCost }),
      // Array with all data
      setProviderData: (newProviderData) =>
        set({ providerData: newProviderData }),
    }),
    {
      name: 'flowProviderStore',
    }
  )
);

export default useFlowProviderStore;
